// 3rd party
import { useState, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import { getModalData } from '@/lib/contentful/modal'

// Helpers
import { formatUrlSlug, validURL, getContentModel } from '@/lib/helper'
import { enableBodyScroll } from '@/lib/helper'
import { viewContentPromotionEvent, selectContentPromotionEvent } from '@/lib/ga-events'

// Components
import Icon from '@/components/Icon/Icon'

//gta
import { ctaClick } from '@/lib/ga-events'

import { default as pagePaths } from '../../public/promotion-urls.json'
import useIsInViewport from '@/hooks/useIntersection'

const DynamicVideoPlayer = dynamic(() => import('@/components/VideoPlayer'), {
  loading: () => null,
  ssr: false,
})

const DynamicModal = dynamic(() => import('@/components/Modal/Modal'), {
  loading: () => null,
  ssr: false,
})

const CTA = ({
  className,
  inlineStyles,
  label,
  openInNewTab = false,
  openVideoPlayer = false,
  scroll = true,
  url,
  background,
  moduleProps = null
}) => {
  const ref = useRef(null)
  const inViewport = useIsInViewport(ref)
  const [isVideoPlayerOpen, setIsVideoPlayerOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [success, setSuccess] = useState(false);
  const router = useRouter()
  let pageModel = getContentModel('page')
  let heroModel = getContentModel('moduleHero')
  
  const handlePlayClick = (open) => {
    enableBodyScroll(!open)
    setIsVideoPlayerOpen(open)
  }

  const formattedUrl = formatUrlSlug(url)

  useEffect(() => {
    let isMounted = true

    if (url?.startsWith('#') && url?.length > 1) {
      getModalData(url).then((response) => {
        if (response?.success && isMounted) {
          setModalData(response?.data)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [])
  
  useEffect(() => {
    if(inViewport) {
      if (success) return;
      const sucessFunction = async () => {
        try {
          let currentPath = router.asPath;
          let promotionId = (process.env.NEXT_PUBLIC_VERCEL_URL || process.env.NEXT_PUBLIC_BASE_URL)+currentPath;
          promotionId = promotionId.startsWith('http') ? promotionId : 'https://'+promotionId

          //PROMOTION NAME & CREATIVE NAME & CREATIVE SLOT & locationId
          let promotionName
          let creativeName
          let creativeSlot
          let locationId
          if(currentPath == '/') {
            promotionName = pageModel.name
          } else {
            let pageType = pagePaths.find(page => page.path == currentPath)
            promotionName = pageType?.type ? getContentModel(pageType?.type)?.name : pageModel.name
            // promotionName = promotionName+' :: Module | Hero Section'
          }
          let allowTrigger = false
          if(moduleProps) {
            let contentType = moduleProps?.sys?.contentType?.sys?.id || moduleProps?.contentType
            creativeName = moduleProps?.entryTitle || moduleProps?.fields?.entryTitle || moduleProps?.textAndImage || moduleProps?.sectionName
            locationId = moduleProps?.index ? moduleProps?.index + 1 : 1
            let articleCheck = getContentModel('article')?.name
            if(promotionName.includes(articleCheck)) {
              locationId += 1
            }
            creativeSlot = `Modules::${locationId}`
            if(moduleProps?.categoryLevel) {
              locationId = 'Navigation::'+moduleProps?.categoryLevel
              creativeSlot = 'Marketing Content'
            }
            if(contentType) {
              let contentModel = getContentModel(contentType)
              switch (contentType) {
                case 'moduleHero':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                case 'moduleNavigationContentBlock':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}::${heroModel.name}`
                  break;
                case 'moduleHeroSection':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                case 'moduleContent2Images':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                case 'moduleContent2Blocks':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                case 'productFeatures':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                case 'moduleContent3Blocks':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                case 'moduleGlobalBrands':
                  promotionName = `${promotionName}::${contentModel.name}`
                  allowTrigger = false
                  break;
                case 'modulePageDescription':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                case 'module3upFeature':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                default:
                  break;
              }
            }
          }
          let eventData = [
            {
              promotion_id: promotionId,
              promotion_name: promotionName,
              creative_name: creativeName,
              creative_slot: creativeSlot,
              location_id: locationId
            }
          ]
          let executed = false
          if(promotionId && promotionName && creativeName && creativeSlot && locationId && allowTrigger) {
            executed = viewContentPromotionEvent(eventData)
          }
          if(executed) {
            setSuccess(true);
          }
        } catch (error) {
          console.error('View Promotion execution failed:', error);
        }
      };
  
      const intervalId = setInterval(async () => {
        if (!success) {
          await sucessFunction();
        } else {
          clearInterval(intervalId);
        }
      }, 500);
  
      return () => clearInterval(intervalId);
    }
  }, [inViewport, success, router.asPath])

  const selectPromotionEvent = () => {
    let currentPath = router.asPath;
    let promotionId = (process.env.NEXT_PUBLIC_VERCEL_URL || process.env.NEXT_PUBLIC_BASE_URL)+currentPath;
    promotionId = promotionId.startsWith('http') ? promotionId : 'https://'+promotionId

    //PROMOTION NAME & CREATIVE NAME & CREATIVE SLOT & locationId
    let promotionName
    let creativeName
    let creativeSlot
    let locationId
    if(currentPath == '/') {
      promotionName = pageModel.name
    } else {
      let pageType = pagePaths.find(page => page.path == currentPath)
      promotionName = pageType?.type ? getContentModel(pageType?.type)?.name : pageModel.name
    }
    if(moduleProps) {
      let contentType = moduleProps?.sys?.contentType?.sys?.id || moduleProps?.contentType
      if(contentType) {
        let contentModel = getContentModel(contentType)
        let ctaModel = getContentModel('moduleCta')
        switch (contentType) {
          case 'moduleHero':
            promotionName = `${promotionName}::${contentModel.name}::${ctaModel.name}`
            let ctaField = contentModel.fields.find(field => field.id == 'ctas')
            if(moduleProps?.fields?.ctas && moduleProps?.fields?.ctas?.length > 0) {
              for(let counter=0; counter<moduleProps?.fields?.ctas.length; counter++) {
                if(moduleProps?.fields?.ctas[counter]?.fields && moduleProps?.fields?.ctas[counter]?.fields?.url == url) {
                  creativeName = moduleProps?.fields?.ctas[counter]?.fields?.entryTitle
                  creativeSlot = `${ctaField?.name || 'CTAs'}::${counter+1}`
                  locationId = moduleProps?.index + 1 || 0
                  break
                }
              }
            }
            break;
          case 'moduleNavigationContentBlock':
            promotionName = `${promotionName}::${contentModel.name}::${heroModel.name}::${ctaModel.name}`
            let ctasField = contentModel.fields.find(field => field.id == 'ctas')
            if(moduleProps?.fields?.ctas && moduleProps?.fields?.ctas?.length > 0) {
              for(let counter=0; counter<moduleProps?.fields?.ctas.length; counter++) {
                if(moduleProps?.fields?.ctas[counter]?.fields && moduleProps?.fields?.ctas[counter]?.fields?.url == url) {
                  creativeName = moduleProps?.fields?.ctas[counter]?.fields?.entryTitle
                  creativeSlot = `${ctasField?.name || 'CTAs'}::${counter+1}`
                  locationId = 1
                  break
                }
              }
            }
            break;
          case 'moduleSliders':
            promotionName = `${promotionName}::${contentModel.name}::${ctaModel.name}`
            if(moduleProps?.cta?.url == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'cta')
              creativeName = moduleProps?.cta?.entryTitle || moduleProps?.cta?.label
              creativeSlot = ctaField?.name || 'CTA'
              locationId = moduleProps?.index + 1 || 0
            } else if(moduleProps?.subtitleCta?.url == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'subtitleCta')
              creativeName = moduleProps?.subtitleCta?.entryTitle || moduleProps?.subtitleCta?.label
              creativeSlot = ctaField?.name || 'Sub Title CTA'
              locationId = moduleProps?.index + 1 || 0
            }
            break;
          case 'moduleHeroSection':
            promotionName = `${promotionName}::${contentModel.name}::${ctaModel.name}`
            if(moduleProps?.ctaLeft?.url == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'ctaLeft')
              creativeName = moduleProps?.ctaLeft?.entryTitle
              creativeSlot = ctaField?.name || 'CTA Left'
              locationId = moduleProps?.index + 1 || 0
            } else if(moduleProps?.ctaRight?.url == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'ctaRight')
              creativeName = moduleProps?.ctaRight?.entryTitle
              creativeSlot = ctaField?.name || 'CTA Right'
              locationId = moduleProps?.index + 1 || 0
            }
            break;
          case 'moduleContent2Images':
            promotionName = `${promotionName}::${contentModel.name}::${ctaModel.name}`
            if(moduleProps?.ctaLeft1?.url == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'ctaLeft1')
              creativeName = moduleProps?.ctaLeft1?.entryTitle
              creativeSlot = ctaField?.name || 'CTA Left 1'
              locationId = moduleProps?.index + 1 || 0
            } else if(moduleProps?.ctaLeft2?.url == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'ctaLeft2')
              creativeName = moduleProps?.ctaLeft2?.entryTitle
              creativeSlot = ctaField?.name || 'CTA Left 2'
              locationId = moduleProps?.index + 1 || 0
            } else if(moduleProps?.ctaRight1?.url == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'ctaRight1')
              creativeName = moduleProps?.ctaRight1?.entryTitle
              creativeSlot = ctaField?.name || 'CTA Right 1'
              locationId = moduleProps?.index + 1 || 0
            } else if(moduleProps?.ctaRight2?.url == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'ctaRight2')
              creativeName = moduleProps?.ctaRight2?.entryTitle
              creativeSlot = ctaField?.name || 'CTA Right 2'
              locationId = moduleProps?.index + 1 || 0
            }
            break;
          case 'moduleContent2Blocks':
            promotionName = `${promotionName}::${contentModel.name}::${ctaModel.name}`
            if(moduleProps?.primaryCta?.url == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'primaryCta')
              creativeName = moduleProps?.primaryCta?.entryTitle
              creativeSlot = ctaField?.name || 'Primary CTA'
              locationId = moduleProps?.index + 1 || 0
            } else if(moduleProps?.secondaryCta?.url == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'secondaryCta')
              creativeName = moduleProps?.secondaryCta?.entryTitle
              creativeSlot = ctaField?.name || 'Secondary CTA'
              locationId = moduleProps?.index + 1 || 0
            }
            break;
          case 'productFeatures':
            promotionName = `${promotionName}::${contentModel.name}::${ctaModel.name}`
            if(moduleProps?.ctaLeftUrl == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'primaryCta')
              creativeName = moduleProps?.ctaLeftText
              creativeSlot = ctaField?.name || 'CTA Left URL'
              locationId = moduleProps?.index + 1 || 0
            } 
            break;
          case 'moduleContent3Blocks':
            promotionName = `${promotionName}::${contentModel.name}::${ctaModel.name}`
            if(moduleProps?.ctaLeftPrimary?.url == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'ctaLeftPrimary')
              creativeName = moduleProps?.ctaLeftPrimary?.entryTitle
              creativeSlot = ctaField?.name || 'CTA Left Primary'
              locationId = moduleProps?.index + 1 || 0
            } else if(moduleProps?.ctaLeftSecondary?.url == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'ctaLeftSecondary')
              creativeName = moduleProps?.ctaLeftSecondary?.entryTitle
              creativeSlot = ctaField?.name || 'CTA Left Secondary'
              locationId = moduleProps?.index + 1 || 0
            } else if(moduleProps?.ctaRightBottom?.url == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'ctaRightBottom')
              creativeName = moduleProps?.ctaRightBottom?.entryTitle
              creativeSlot = ctaField?.name || 'CTA Right Bottom'
              locationId = moduleProps?.index + 1 || 0
            } else if(moduleProps?.ctaRightTop?.url == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'ctaRightTop')
              creativeName = moduleProps?.ctaRightTop?.entryTitle
              creativeSlot = ctaField?.name || 'CTA Right Top'
              locationId = moduleProps?.index + 1 || 0
            }
            break;
          case 'moduleGlobalBrands':
            promotionName = `${promotionName}::${contentModel.name}::${ctaModel.name}`
            if(moduleProps?.cta1?.url == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'cta1')
              creativeName = moduleProps?.cta1?.entryTitle
              creativeSlot = ctaField?.name || 'CTA 1'
              locationId = moduleProps?.index + 1 || 0
            } else if(moduleProps?.cta2?.url == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'cta2')
              creativeName = moduleProps?.cta2?.entryTitle
              creativeSlot = ctaField?.name || 'CTA 2'
              locationId = moduleProps?.index + 1 || 0
            } else if(moduleProps?.cta3?.url == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'cta3')
              creativeName = moduleProps?.cta3?.entryTitle
              creativeSlot = ctaField?.name || 'CTA 3'
              locationId = moduleProps?.index + 1 || 0
            }
            break;
          case 'modulePageDescription':
            promotionName = `${promotionName}::${contentModel.name}::${ctaModel.name}`
            if(moduleProps?.fields?.cta?.fields?.url == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'cta')
              creativeName = moduleProps?.fields?.cta?.fields?.entryTitle
              creativeSlot = ctaField?.name || 'CTA'
              locationId = moduleProps?.index + 1 || 0
            }
            break;
          case 'module3upFeature':
            promotionName = `${promotionName}::${contentModel.name}::${ctaModel.name}`
            if(moduleProps?.fields?.link1 == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'link1')
              creativeName = label
              creativeSlot = ctaField?.name || 'link1'
              locationId = moduleProps?.index + 1 || 0
            } else if(moduleProps?.fields?.link2 == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'link2')
              creativeName = label
              creativeSlot = ctaField?.name || 'link2'
              locationId = moduleProps?.index + 1 || 0
            } else if(moduleProps?.fields?.link3 == url) {
              let ctaField = contentModel.fields.find(field => field.id == 'link3')
              creativeName = label
              creativeSlot = ctaField?.name || 'link3'
              locationId = moduleProps?.index + 1 || 0
            }
            break;
          default:
            break;
        }
      }
    }
    let articleCheck = getContentModel('article')?.name
    if(promotionName.includes(articleCheck)) {
      locationId += 1
    }
    if(promotionId && promotionName && creativeName && creativeSlot && locationId) {
      let eventData = [
        {
          promotion_id: promotionId,
          promotion_name: promotionName,
          creative_name: creativeName,
          creative_slot: creativeSlot,
          location_id: locationId
        }
      ]
      selectContentPromotionEvent(eventData)
    }
  }

  return (
    <div ref={ref} onClick={() => selectPromotionEvent()}>
      {openVideoPlayer ? (
        <>
          <button className={`${className} button-bg`} onClick={() => handlePlayClick(true)}>
            <span className="inline-flex justify-center items-center gap-2">
              {label}
              <Icon viewBox="2 2 20 20" size={16} icon={'play'} />
            </span>
          </button>
          <DynamicVideoPlayer
            isOpen={isVideoPlayerOpen}
            closeCallback={() => handlePlayClick(false)}
            url={url}
          />
        </>
      ) : validURL(url) ? (
        <a
          className={`${className} ${className.includes('btn') && 'button-bg'}`}
          href={formattedUrl}
          rel={openInNewTab ? 'noopener noreferrer' : ''}
          target={openInNewTab ? '_blank' : '_self'}
          onClick={() => ctaClick(formattedUrl, label)}
        >
          {label}
        </a>
      ) : (
        <>
          {url?.startsWith('#') && url?.length > 1 ? (
            <>
              <button
                className={`${className} button-bg`}
                onClick={() => {
                  if (modalData) {
                    setOpenModal(true)
                  } else {
                    const targetElement = document.querySelector(url)
                    if (targetElement) {
                      targetElement.scrollIntoView({ block: 'start' })
                    }
                  }
                }}
              >
                <span className="inline-flex justify-center items-center gap-2">{label}</span>
              </button>
              {modalData && (
                <DynamicModal {...modalData} isOpen={openModal} setIsOpen={setOpenModal} />
              )}
            </>
          ) : (
            <Link href={formattedUrl} passHref scroll={scroll}>
              <a
                className={`${className} ${className.includes('btn') && 'button-bg'}`}
                target={openInNewTab ? '_blank' : '_self'}
                onClick={() => ctaClick(formattedUrl, label)}
              >
                {label}
              </a>
            </Link>
          )}
        </>
      )}

      <style jsx>{`
        .button-bg {
          background-color: ${background && background == 'Gray'
            ? '#97A3AE'
            : background && background == 'Orange'
            ? '#DD5F13'
            : 'white'};
          border: 1px solid
            ${background && background == 'Gray'
              ? '#97A3AE'
              : background && background == 'Orange'
              ? '#DD5F13'
              : 'black'};
          border-radius: 2px;
        }
        ${inlineStyles}
      `}</style>
    </div>
  )
}

export default CTA